import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles'
import MeritPage from './pages/Merit/MeritPage';
import MeritLinkPage from './pages/Merit/MeritLinkPage';
import LoginPage from './pages/Login/LoginPage';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  content: {
    margin: 'auto',
    width: 200,
    alignItems: 'center',
    justifyContent: 'center',
  },
  app: {
    width: "90vw",
    minHeight: "100vh",
    alignItems: 'center',
    justifyContent: 'center',
    margin: "10px"
  }
}))

const App: React.FC = () => {
  //const classes = useStyles();

  return (
    <Router>
      <CssBaseline />
      {/*<div className={classes.app}>*/}
        {/* <img alt="skycru" className={classes.logo} src={logoSkycru} />
        <nav hidden>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/linkapp">Link App</Link>
            </li>
            <li>
              <a href="/auth/logout">Logout</a>
            </li>
          </ul>
        </nav> */}

        {/*<div className={classes.content}>*/}
        <Switch>
          <Route path="/linkapp">
            <MeritLinkPage />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/">
            <MeritPage />
          </Route>
        </Switch>
      {/*  </div>*/}
      {/*</div>*/}
    </Router>
  );
}

export default App;
