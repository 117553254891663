import React, { useState, useEffect, useCallback } from 'react';
import {Merit} from '../../components/Merit';
import { Button } from 'tabler-react';
import { makeStyles } from '@material-ui/core/styles'
import Header from '../../components/Header';

const useStyles = makeStyles(theme => ({
  button: {
    margin: '10px'
  }
}))

const MeritPage = () => {
  const [loading, setLoading] = useState(false);
  const [meritConfigUnknown, setMeritConfig] = useState({});
  const classes = useStyles();

  const meritConfig: any = meritConfigUnknown || {};
  const merits: any[] = meritConfig.merits || [];
  const meritLoginUrl: string = "/auth/merits/login"

  //const meritsMembership = merits.filter(m => m.)
  const queryServer = (url: string) => {
    setLoading(true);
    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    }).then( async (rsp: any) => {
      const message: any = await rsp.json();
      setMeritConfig(message);
    }).catch( error => {
      console.log('error', error);
    }).finally( () => {
      setLoading(false);
    });
  }
  const current = useCallback(() => {
    queryServer('/auth/merit/current');
  }, []);

  const refresh = () => {
    queryServer('/auth/merit/refresh');
  }

  const unlink = () => {
    queryServer('/auth/merit/unlink');
  }

  useEffect(() => {
    current();
  }, [current]);

  return (
    <div className="page">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col col-login mx-auto">
              <div className="text-center mb-6">
                <p><Header /></p>
                { !meritConfig.loggedin && (
                  <div className="text-center mt-6 mb-6">
                    <p>You need to be <a href="/login">logged in</a> to SKYCRU</p>
                  </div>
                ) }
                { meritConfig.loggedin && (
                  <div>
                    <p>Logged in with SKYCRU: {meritConfig.email}. <a href="/auth/logout">Logout</a></p>
                    { meritConfig.linked && (
                      <div>
                        <p>You are currently linked to <a href="https://www.merits.com/" target="_blank">Merit</a>.</p>
                        <Button className={classes.button} variant="contained" color="primary" disabled={loading} onClick={refresh}>Refresh Merits</Button>
                        { merits.length === 0 && (
                          <p>No merits found</p>
                        )}
                        { merits.map((m: any) => <Merit key={m.id} data={m} /> )}
                        {

                        }
                        <Button className={classes.button} variant="contained" color="primary" disabled={loading} onClick={unlink}>Unlink Merit</Button>
                      </div>
                    )}
                    { !meritConfig.linked && (
                      <div>
                        <p>You are not linked to <a href="https://www.merits.com/" target="_blank">Merit</a>.</p>
                        <Button className={classes.button} variant="contained" color="primary" onClick={() => { window.location.pathname=meritLoginUrl }}>Login with Merit</Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeritPage;
