import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

const MeritLinkPage = () => {
  const [loading, setLoading] = useState(false);

  function goLinkApp() {
    setLoading(true);
    fetch('auth/merit/linkapp', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }).then( async (rsp: any) => {
      const message: any = await rsp.json();
      window.location = message['request_linkapp_url'];
    }).catch( error => {
      console.log('error', error);
    }).finally( () => {
      setLoading(false);
    });
  }

  return (
    <div>
      <Button variant="contained" disabled={loading} color="primary" onClick={goLinkApp}>Link App</Button>
    </div>
  );
}

export default MeritLinkPage;
