import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import moment from 'moment';

const Merit = (props: { data: any  }) => {
  const {data} = props;
  return (
    <Card className="Merit mb-3">
      <CardContent>
        <h2>{data.template_title}</h2>
        {data.merits_uspa_member_id && (
          <>
            <div><strong>{data.first_name} {data.last_name}</strong></div>
            <div> USPA Member #: {data.merits_uspa_member_id}</div>
          </>
        )}
        {data.merits_uspa_member_expiration && (
          <div> Expires: {moment(data.merits_uspa_member_expiration).format("MM/DD/YYYY")}</div>
        )}
      </CardContent>
    </Card>
  );
}

export { Merit }
