import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import logoImg from '../images/logo.png';

const useStyles = makeStyles(theme => ({
  logo: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    width: "300px",
    maxWidth: "80vw"
  }
}))

const Header = () => {
  const classes = useStyles();
  return (
    <div>
      <a href="/"><img alt="SKYCRU" className={classes.logo} src={logoImg} /></a>
    </div>
  );
}

export default Header;
