// @ts-nocheck
import React, { useRef } from 'react';
import { withFormik } from 'formik';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';

const LoginForm = () => {
  const formEl = useRef(null);

  const doHtmlFormPost = (values: any) => {
    let current: any = formEl.current
    current.submit()
  };

  const validate =  (values: any) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (values.email === 'admin') {
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }
    return errors;
  }

  const Form = withFormik({
    mapPropsToValues: () => ({ email: '', password: '' }),
    handleSubmit: doHtmlFormPost,
    validate
  })(props => {
    const { handleBlur, handleChange, handleSubmit, touched, values, errors, isSubmitting } = props;

    return (
      <form
        className='card'
        method='POST'
        action='/auth/login'
        ref={formEl}
        onSubmit={handleSubmit}
      >
        <div className='card-body p-6'>
          <div className="form-group ">
            <label className="form-label">Email</label>
            <div className="input-group">
              <input
                id="email"
                name="email"
                type="text"
                className='form-control'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                autoFocus required />
            </div>
            <span className="field-error text-danger">{errors.email && touched.email && errors.email}</span>
          </div>
          <div className="form-group ">
            <label className="form-label">Password</label>
            <div className="input-group">
              <input
                id="password"
                type="password"
                name="password"
                className='form-control'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                required />

            </div>
            <span className="field-error text-danger">{errors.password && touched.password && errors.password}</span>
          </div>
          <div className='form-footer mb-6'>
        <button className='btn btn-block btn-primary' color="primary" type="submit" disabled={isSubmitting}>LOGIN</button>
          </div>
        </div>
      </form>
    );
  })
  return <Form />
}

const LoginPage = () => {
  return (
      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className="col col-login mx-auto">
                <div className="text-center mb-6">
                <Link to="/">
                  <img src={logo} className="h-6" alt="logo" />
                </Link>
                </div>
                <LoginForm />
                <div className="text-center mt-6 mb-6">Sync SKYCRU with Merit/USPA</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default LoginPage;
